import { useState, useEffect } from "react";

import "./App.css";

const PLAYER_ID = Math.round(Math.random() * 100);
const BASE_URL = "https://teamrocket.fromdeep.space/api/events";
// const BASE_URL = "http://localhost:8000/events";

function App() {
  const [data, setData] = useState();
  const [backgroundColor, setBackgroundColor] = useState();
  const [isError, setIsError] = useState();

  useEffect(() => {
    console.log(data?.type)
    if(data?.type === 'color'){
      setBackgroundColor(data.color)
    }
  }, [data]);

  const postColor = () => {
    fetch(`${BASE_URL}?id=${PLAYER_ID}`, { method: "POST" })
      .then((response) =>
        response.status === 200 ? response.json() : setIsError(true)
      )
      .catch((err) => setIsError(true));
  };

 useEffect(() => {
   const source = new EventSource(`${BASE_URL}?id=${PLAYER_ID}`);

   source.addEventListener("open", () => {
     console.log("SSE opened!");
   });

   source.addEventListener("message", (e) => {
     const data = JSON.parse(e.data);
     setData(data);
   });

   source.addEventListener("error", (e) => {
     console.error("Error: ", e);
   });

   return () => {
     source.close();
   };
 }, []);  

  return (
    <div className="App" style={{ backgroundColor: `${backgroundColor}` }}>
      <div>ID: {PLAYER_ID}</div>
      <div>Data: {data?.type}</div>
      <div>Error: {isError}</div>
      <button onClick={postColor}>Change color</button>
    </div>
  );
}

export default App;
